import React from 'react'
import Header from '../Components/Header'

const dias = () => {
    return (
      <div>
        <Header/>
          <h1>dias</h1>
        
      </div>
    )
  }
  
  export default dias