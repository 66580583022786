import styled from "styled-components";
import { Link } from "react-router-dom";
import Fundo from "../Assets/fundo.png"
import Logo from "../Assets/logo-rifidim.png"

export const Menu = styled.div`
border:solid;
width:100%;
height:52vh;
background-image:url(${Fundo});
`
export const Menu2 = styled.div`
border:solid white;
display:flex;
height:12vh;
width:100%;
justify-content:space-around;
margin-top:2vh;

`
export const Principal = styled.div`
display:flex;
justify-content:space-around;
align-items:center;
border:solid;
width:70vw;
margin-left:12vw;
`

export const Imagem = styled.div`
background-image:url(${Logo});
border:solid;
height:32vh;
background-repeat:no-repeat;
width:28vw;
margin-left:2vh;
`

export const Titulo = styled.h1`
font-family: 'Inter', sans-serif;
color:white;
font-size:2.3vw;
font-weight:300;
`
export const Subtitulo = styled.h2`
font-family: 'IBM Plex Sans', sans-serif;
color:white;
font-size:5.5vh;
font-weight:1000;
`
export const Linha = styled.hr`
width:17vh;
border-color:red;
height:2px;
`
export const Palavra = styled.p`
font-family: 'Inter', sans-serif;
color:white;
font-size:1.5rem;
border:solid;
margin-top:10px;
width:26vw;
font-weight:300;

`