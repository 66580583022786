import React from 'react'
import { GlobalStyle } from './Styles/Global'
import StyleHeader from './Styles/StylesHeader'

import Rotas from "./Routes/Rotas"



const App = () => {
  return (
    <div>
      <GlobalStyle/>
<Rotas/>
    </div>
  )
}

export default App