import React from 'react'
import Header from '../Components/Header'

const doe = () => {
    return (
      <div>
        <Header/>
          <h1>doe</h1>

      </div>
    )
  }
  
  export default doe