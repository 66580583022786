import React from 'react'
import Header from '../Components/Header'

const Sobre = () => {
  return (
    <div>   
      <Header/>
       <h1>Sobre</h1>
    </div>
  )
}

export default Sobre