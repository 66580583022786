import React from 'react'
import Header from '../Components/Header'




const Home = () => {
  return (
    <div>

       <Header/>
    </div>
  )
}

export default Home